import React from "react";
import Layout from "components/Layout";
import Newsletter from "components/newsletter";


const NotFoundPage = () => (
    <Layout>
        <h1>
            NOT FOUND
        </h1>
        <p>
            You just hit a route that doesn&#39;t exist... the sadness. <br /><br />
            However, that might be a good moment to subscribe to a newsletter that may <strong>change your life</strong>.
        </p>
          <Newsletter />
    </Layout>
)

export default NotFoundPage;
